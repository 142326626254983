import React from "react";
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBIcon,
  MDBCard,
  MDBCardTitle,
  MDBCardImage,
  MDBCardText,
  MDBAnimation,
  MDBNavLink, MDBInput, MDBBtn
} from "mdbreact";
import "./HomePage.css";
import SectionContainer from "../components/sectionContainer";

class HomePage extends React.Component {
  scrollToTop = () => window.scrollTo(0, 0);
  render() {
    return (
      <>
        <MDBEdgeHeader id="home" color="white" className="sectionPage" />
        <div className="mt-3 mb-5">
          <MDBFreeBird>
            <MDBRow>
              <MDBCol
                md="6"
                className="mx-auto float-none white opacity-75 z-depth-1 py-2 px-2"
              >
                <MDBCardBody className="text-center">
                  <h2 className="h2-responsive mb-4">
                    <strong className="font-weight-bold">
                      DAY11 Presale Starting Soon...
                    </strong>
                  </h2>
                  <MDBRow />
                  <SectionContainer header="" className="d-flex justify-content-center" noBorder>
                    <MDBCol md="12">
                        <form>
                          <p className="h5 text-center mb-4">Deposit</p>
                          <div className="grey-text">
                            <MDBInput
                                label="USDT Amount"
                                icon="coins"
                                containerClass='d-flex flex-row-reverse'
                                className='ml-0 mr-5'
                                labelClass='ml-0'
                                group
                                type="number"
                                validate
                                error="number required"
                                success="right"
                            />
                            <MDBInput
                                label="Referral Address"
                                icon="wallet"
                                containerClass='d-flex flex-row-reverse'
                                className='ml-0 mr-5'
                                labelClass='ml-0'
                                group
                                type="text"
                                validate
                                error="wrong"
                                success="right"
                            />
                          </div>
                          <div className="text-center">
                            <MDBBtn color="primary">Approve</MDBBtn>
                          </div>
                        </form>
                      </MDBCol>
                  </SectionContainer>
                  <MDBRow className="d-flex flex-row justify-content-center row">
                    <MDBNavLink
                        tag="button"
                        to="/whitepaper"
                        color="mdb-color"
                        className="border nav-link border-light rounded mr-1 mx-2 mb-2"
                        onClick={this.scrollToTop}
                    >
                      <MDBIcon icon="coins" className="mr-2" />
                      <span className="font-weight-bold">Get Started</span>
                    </MDBNavLink>
                    <MDBNavLink
                        tag="button"
                        to="/whitepaper"
                        color="mdb-color"
                        className="border nav-link border-light rounded mr-1 mx-2 mb-2"
                        onClick={this.scrollToTop}
                    >
                      <MDBIcon icon="graduation-cap" className="mr-2" />
                      <span className="font-weight-bold">Whitepaper</span>
                    </MDBNavLink>
                  </MDBRow>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBFreeBird>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12" className="mt-4">
                <h2 className="text-center my-5 font-weight-bold">
                  Why is Day11 so great?
                </h2>
                <p className="text-center text-muted mb-1">
                  Day11 is a new generation cryptocurrency investment platform where everyone profits.
                  The smart contract is immutable and fully decentralized meaning that your funds are safe.
                  Those that join the presale will get <b>1% daily interest</b> on their funds when Day11 launches.
                  Anyone joining after launch will start on 0.8% daily interest, which increases to 1% after 55 days.
                  These amazing earnings are made possible due to Day11 launching with both <b>real world and crypto revenue streams</b>.
                  Every 11 days you may claim your interest earned or redeposit it.
                  For every $100 invested, <b>a tree will be planted</b> on your behalf, in Africa.
                </p>
                <hr className="my-5" />

                <MDBRow id="categories">
                  <MDBCol md="4">
                    <MDBAnimation reveal type="fadeInLeft">
                      <MDBCard cascade className="my-3 grey lighten-4">
                        <MDBCardImage
                          cascade
                          className="img-fluid"
                          src="everyone_profits.png"
                        />
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <strong>EVERYONE PROFITS<br/>&nbsp;</strong>
                          </MDBCardTitle>
                          <MDBNavLink
                              tag="button"
                              to="/whitepaper"
                              color="mdb-color"
                              className="btn btn-outline-mdb-color btn-sm btn-rounded d-inline"
                              onClick={this.scrollToTop}
                            >
                              More
                          </MDBNavLink>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBAnimation>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBAnimation reveal type="fadeInDown">
                      <MDBCard cascade className="my-3 grey lighten-4">
                        <MDBCardImage
                          cascade
                          className="img-fluid"
                          src="1percentaday.png"
                        />
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>

                            <strong>1% A DAY INTEREST<br/>&nbsp;</strong>
                          </MDBCardTitle>
                          <MDBNavLink
                              tag="button"
                              to="/whitepaper"
                              color="mdb-color"
                              className="btn btn-outline-mdb-color btn-sm btn-rounded d-inline"
                              onClick={this.scrollToTop}
                            >
                              More
                          </MDBNavLink>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBAnimation>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBAnimation reveal type="fadeInRight">
                      <MDBCard cascade className="my-3 grey lighten-4">
                        <MDBCardImage
                          cascade
                          className="img-fluid"
                          src="immutable_smart_contract.png"
                        />
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>

                            <strong>IMMUTABLE SMART CONTRACT</strong>
                          </MDBCardTitle>
                          <MDBNavLink 
                              tag="button"
                              to="/whitepaper"
                              color="mdb-color"
                              className="btn btn-outline-mdb-color btn-sm btn-rounded d-inline"
                              onClick={this.scrollToTop}
                            >
                              More
                          </MDBNavLink>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBAnimation>
                  </MDBCol>
                </MDBRow>

                <MDBRow id="categories">
                  <MDBCol md="4">
                    <MDBAnimation reveal type="fadeInLeft">
                      <MDBCard cascade className="my-3 grey lighten-4">
                        <MDBCardImage
                          cascade
                          className="img-fluid"
                          src="revenue_stream.png"
                        />
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>

                            <strong>REVENUE STREAM<br/>&nbsp;</strong>
                          </MDBCardTitle>
                          <MDBNavLink 
                              tag="button"
                              to="/whitepaper"
                              color="mdb-color"
                              className="btn btn-outline-mdb-color btn-sm btn-rounded d-inline"
                              onClick={this.scrollToTop}
                            >
                              More
                          </MDBNavLink>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBAnimation>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBAnimation reveal type="fadeIn">
                      <MDBCard cascade className="my-3 grey lighten-4">
                        <MDBCardImage
                          cascade
                          className="img-fluid"
                          src="withdraw_stake.png"
                        />
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>

                            <strong>WITHDRAW STAKE<br/>&nbsp;</strong>
                          </MDBCardTitle>
                          <MDBNavLink 
                              tag="button"
                              to="/whitepaper"
                              color="mdb-color"
                              className="btn btn-outline-mdb-color btn-sm btn-rounded d-inline"
                              onClick={this.scrollToTop}
                            >
                              More
                          </MDBNavLink>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBAnimation>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBAnimation reveal type="fadeInRight">
                      <MDBCard cascade className="my-3 grey lighten-4">
                        <MDBCardImage
                          cascade
                          className="img-fluid"
                          src="tree_planting.png"
                        />
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>

                            <strong>TREES PLANTED<br/>IN AFRICA</strong>
                          </MDBCardTitle>

                          <MDBNavLink 
                              tag="button"
                              to="/whitepaper"
                              color="mdb-color"
                              className="btn btn-outline-mdb-color btn-sm btn-rounded d-inline"
                              onClick={this.scrollToTop}
                            >
                              More
                          </MDBNavLink>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBAnimation>
                  </MDBCol>
                </MDBRow>

              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </>
    );
  }
}

export default HomePage;
