import React from "react";
import { useState } from "react";
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBIcon,
  MDBAnimation, MDBCardImage, MDBCard, MDBCardBody, MDBCardTitle, MDBNavLink
} from "mdbreact";
import MenuLink from "./../components/menuLink";
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import './Whitepaper.css';

const Whitepaper = () => {
  const [activeItem, setActiveItem] = useState(1);

  return (
    <>
    <MDBEdgeHeader color="white" className="sectionPage" style={{height:"40px"}}/>
      <MDBAnimation type="zoomIn" duration="500ms">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="10" className="mx-auto">
                  <h2 className="text-center" style={{margin:"25px 0"}}>
                    <MDBIcon icon="graduation-cap" className="mr-2" />
                    <b>Whitepaper</b>
                  </h2>
                  <MDBAccordion initialActive={1} active={activeItem} onChange={(itemId) => setActiveItem(itemId)}>
                    <MDBAccordionItem collapseId={1} headerTitle='Overview'>
                      Day11 is a new generation cryptocurrency <b>investment fund</b>.
                      It has some similarities to a traditional investment fund such as dividends, although it's very much more generous with dividends paid daily as Daily Interest.
                      Those that join the presale will get <b>1% daily interest</b> on their funds when Day11 launches.
                      Anyone joining after launch will start on 0.8% daily interest, which increases to 1% after 55 days.
                      Daily Interest can be claimed every 11 days.
                      These amazing earnings are made possible due to Day11 launching with both <b>real world and crypto revenue streams</b>.<br/><br/>
                      The Day11 Smart Contract is immutable and fully decentralized meaning that your funds are safe.
                      Also similar to a traditional investment fund, your deposits may be withdrawn, and although the deposit term is approx. 100 days, you can partially withdraw during that time.
                      For every $100 deposited, <b>a tree will be planted</b> on your behalf, in Africa.<br/>
                      Seasoned crypto investors see the <a href="#" onClick={(e)=>{e.preventDefault(); setActiveItem(10)}}>Expert Summary</a>
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={2} headerTitle='How Day11 Works'>
                      <MDBContainer className="text-center">
                        <MDBRow>
                          <MDBCol>
                            <img src="how_day11_works.png"/>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol className="mx-auto">
                            <p>&nbsp;</p>
                            <ul style={{width:"83%",marginLeft:"auto",marginRight:"auto",textAlign:"left"}}>
                              <li>Deposits are immediately invested into the Day11 Revenue Stream.</li>
                              <li>The Revenue Stream returns a profit of 2% a day on large capital investments.</li>
                              <li>1% of that return is transferred daily to the Day11 Treasury.</li>
                              <li>Some of the additional 1% a day is also transferred to cover partial Withdraws.</li>
                              <li>The Treasury grows daily according to the 1% Rewards and Withdrawable Amounts.</li>
                              <li>The Treasury is always able to meet its financial obligations for everyone at any time.</li>
                              <li>The immutable Smart Contract ensures that funds can only be removed via Claims or Withdraws.</li>
                            </ul>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={3} headerTitle='Deposit'>
                      <p>
                      To Deposit you will need a cryptocurrency wallet that supports the "Binance Smart Chain" network (BSC), such as Metamask Wallet or Rabby Wallet or equivalent.
                      Then you will need to buy USDT from your preferred crypto exchange. A minimum of 100 USDT is needed so best to buy at least 102 USDT to cover exchange fees.
                      You will also need to buy a small amount of BNB to cover BSC network transaction fees (around $20 worth will last for awhile).
                      Finally once those have been purchased, you can send your USDT and your BNB to your crypto wallet.
                      To do this, copy your wallet address from your crypto wallet by clicking on the wallet address.
                      Then in your crypto exchange choose the Send option and paste your wallet address into the address field, and send the full amount. Repeat with the BNB.
                      You are now ready to Deposit.
                      </p>
                      <p>
                      Now you can go to this site's Home page and connect your wallet via the "Connect Wallet" menu button.
                      In some wallets like Metamask you will be asked by the wallet if its ok to connect, so confirm this.
                      You should see the button change now to show your wallet address (or part of it).
                      Now type in the amount of USDT you are depositing and then press the "Approve" button.
                      Your wallet will ask you to approve an amount that this application is allowed to spend on your behalf.
                      Here you can type in the amount you are expecting to invest in total or else a large number if preferred, so you don't get asked every time.
                      Once confirmed successfully, the button will changed to "Deposit".
                      Now you can press "Deposit" and then confirm within your crypto wallet.
                      Congratulations and welcome to your Day11 success. Please share your Referral Link with others so that they can also profit.
                      <br/>Note that a Deposit has a 5% fee.
                      </p>
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={4} headerTitle='Daily Interest'>
                      Once Day11 launches, those that participated in the Presale will be getting a daily interest rate of 1% on their investment.
                      Those that joined after launch will start on 0.8% a day (which is still phenomenal), which increases to 1% a day after 55 days.
                      Daily Interest is variable as a protection from extreme market fluctuations.
                      However we are expecting to offer the maximum Daily Interest Rate of 1% in an ongoing capacity.
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={5} headerTitle='Claim'>
                      11 days after your last claim you may return to the website to claim your Daily Interest Rewards.
                      Don't worry if you're late, rewards keep increasing for 30 days.
                      But please don't wait more than 60 days, as that will be considered a dead account and likely be closed.
                      Whenever you have Available Rewards, you have the choice of whether to Claim or Redeposit.
                      Redeposit offers a compounding effect which substantially increases your investment.
                      To Claim simply visit this site's Home page and connect your wallet if not already connected.
                      Your Daily Interest Rewards will be displayed, and if the "Claim" button is green you may Claim your Rewards.
                      Ensure you have some BNB to cover network fees, and then you can click "Claim" and confirm in your crypto wallet.
                      Your USDT Rewards will now be in your wallet, which you can spend by sending to your exchange (by following your exchange instructions).
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={6} headerTitle='Withdraw'>
                      The Day11 Smart Contract always holds enough in its Treasury to pay out all possible Withdraw requests.
                      You may Withdraw your investment incrementally over approx. 100 days.
                      This means that 50 days after Deposit, you may Withdraw approx. half your investment.
                      The Smart Contract cannot track dates of every Deposit as space used would cause increasing transaction costs,
                      so it approximates how much you can Withdraw at any time.
                      To Withdraw simply visit this site's Home page, connect your crypto wallet, and the "Available Withdraw Amount" will be shown.
                      Type in your desired amount to Withdraw, and then click the "Withdraw" button and confirm in your crypto wallet.
                      <br/>Note that a Withdraw has a 5% fee.
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={7} headerTitle='Redeposit'>
                      11 days after your last claim, you have the choice of whether to Claim your Daily Interest Rewards or Redeposit them.
                      Redeposit creates a compounding effect and is preferable for growth, however it is also important to have a profit-taking strategy.
                      To Redeposit, visit this site's Home page and connect your crypto wallet.
                      Your Daily Interest Rewards will be displayed, and if the "Redeposit" button is green you may Redeposit.
                      Ensure you have some BNB to cover network fees, and then you can click "Redeposit" and confirm in your crypto wallet.
                      Your Deposit amount will increase accordingly, allowing compound interest to grow your investment.
                      <br/>Note that a Redeposit has a 5% fee.
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={8} headerTitle='Referral Rewards'>
                      If you are enjoying your Day11 journey, why not share details with others and gain Referral Commissions.
                      Day11 is generous in offering a Referral Commission on every Deposit using your Referral. Presale Referral Commission is 5%, and after launch its 4%.
                      Your Referral Link is shown on this site's Home page.
                      Some popular ways of earning from Referral Commissions are writing Medium.com articles, Youtube videos, Instagram and Facebook posts etc.
                      Note that Referral Commissions are only paid to accounts with a positive balance (active).
                    </MDBAccordionItem>
                    <MDBAccordionItem collapseId={9} headerTitle='Your Trees planted in Africa'>
                      Day11 is a community-based project, which is why Day11 profits are generously shared with you daily.
                      We also believe in using profits to make a difference to the environment.
                      For every $100 you invest, a tree will be planted on your behalf in Rwanda in Africa, where more trees are desperately needed.
                    </MDBAccordionItem>
                    <MDBAccordionItem id="expert-summary" collapseId={10} headerTitle='Expert Summary'>
                      <ul>
                        <li>Day11 begins a new generation of ROI dapps where everyone ROI's, and launches with a revenue stream from the beginning.</li>
                        <li>Smart contract is immutable so it can't be rugged, with source code public.</li>
                        <li>Chain is Binance Smart Chain and token is USDT, so no price fluctuations.</li>
                        <li>At any time the Treasury can pay everyone's withdrawable amount, and being immutable your funds are always safu.</li>
                        <li>At launch the Treasury will hold the Total USDT amount Deposited during Presale, presalers may withdraw their Total Deposits at launch.</li>
                        <li>Rewards are variable - Presalers gets 1% a day at launch, anyone joining after launch gets 0.8% a day, and then 1% after 55 days.</li>
                        <li>Every 11 days you can either Claim your rewards or Redeposit (compound).</li>
                        <li>Deposits can be Withdrawn partially over approx. 100 day term - so at approx. 50 days half your Deposit can be withdrawn.</li>
                        <li>Referrals paid on every deposit - 5% commission during Presale, and 4% commission after launch.</li>
                        <li>Fees are 5% for most transactions except Claim which is free.</li>
                        <li>A tree is planted on your behalf in Africa for every $100 you deposit.</li>
                      </ul>
                    </MDBAccordionItem>
                  </MDBAccordion>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBAnimation>
    </>
  );
};

export default Whitepaper;
