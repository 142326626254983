import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBFooter,
  MDBNavLink,
  MDBTooltip,
  MDBIcon, MDBRow, MDBContainer
} from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./styles.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
  connectorsForWallets,
} from '@rainbow-me/rainbowkit';
import {
  rainbowWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { createConfig } from 'wagmi';
import { WagmiProvider } from 'wagmi';
import {
  bsc,
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { metaMaskWallet, okxWallet , rabbyWallet, ledgerWallet } from '@rainbow-me/rainbowkit/wallets';

class App extends Component {
  state = {
    collapseID: ""
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));

  closeCollapse = collapseID => () => {
    window.scrollTo(0, 0);
    this.state.collapseID === collapseID && this.setState({ collapseID: "" });
  };

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.toggleCollapse("mainNavbarCollapse")}
      />
    );
    const { collapseID } = this.state;
    const queryClient = new QueryClient();
    const connectors = connectorsForWallets(
        [
          {
            groupName: 'Recommended',
            wallets: [metaMaskWallet, rabbyWallet, walletConnectWallet, okxWallet, rainbowWallet, ledgerWallet],
          },
        ],
        {
          appName: 'Day11',
          projectId: 'ed06f93e8b78105885f8f1ff8d95af93',
        }
    );
    const config = createConfig({
      connectors,

         appName: 'DAY 11',
         projectId: 'ed06f93e8b78105885f8f1ff8d95af93',
         chains: [bsc],

    });

    return (
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
              <Router>
                <div className="flyout">
                  <MDBNavbar dark expand="md" fixed="top" scrolling style={{ backgroundColor: "#E4A11B" }}>
                    <MDBNavbarBrand href="/" className="py-0 font-weight-bold">
                      <div id="logoContainer">
                        <div id="logo" style={{ width: "2.5rem", height: "2.5rem" }} />
                      </div>
                      <h1 id="logo-text"><strong className="align-middle">DAY11</strong></h1>
                    </MDBNavbarBrand>
                    <MDBNavbarToggler
                      onClick={this.toggleCollapse("mainNavbarCollapse")}
                    />
                    <MDBCollapse
                      id="mainNavbarCollapse"
                      isOpen={this.state.collapseID}
                      navbar
                    >
                      <MDBNavbarNav right>
                        <MDBNavItem>
                          <MDBNavLink
                            exact
                            to="/"
                            onClick={this.closeCollapse("mainNavbarCollapse")}
                          >
                            <strong>Home</strong>
                          </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            onClick={this.closeCollapse("mainNavbarCollapse")}
                            to="/whitepaper"
                          >
                            <strong>Whitepaper</strong>
                          </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                              id="connect-button"
                              onClick={this.closeCollapse("mainNavbarCollapse")}
                              to="#"
                          >
                            <strong> <ConnectButton /></strong>
                          </MDBNavLink>
                        </MDBNavItem>
                      </MDBNavbarNav>
                    </MDBCollapse>
                  </MDBNavbar>
                  {collapseID && overlay}
                  <main style={{ marginTop: "4rem" }}>
                    <Routes />
                  </main>
                  <MDBFooter style={{backgroundColor: "#E4A11B"}}>
                      <p style={{margin:"20px 40px"}}>Disclaimer:<br/>
                        Participation within the Day11 Ecosystem is entirely at your own discretion. Please conduct your own research and read all of the available information. Remember that crypto-currencies and the performance of projects carry no guarantees and you should not take on unnecessary risks.
                        Material published by Day11 should not be considered as financial advice.
                        It is your responsibility not to share your crypto-currency wallet "Private Key" or "Seed Phrase" with any website or person, no matter how official they claim to be.</p>
                      <p className="footer-copyright mb-0 py-3 text-center strong">
                        <b>DAY11.MONEY&nbsp;&nbsp;|&nbsp;&nbsp;Whitepaper&nbsp;&nbsp;|&nbsp;&nbsp;Telegram&nbsp;&nbsp;|&nbsp;&nbsp;X<br/>
                          &copy; {new Date().getFullYear()} Copyright DAY11</b>
                      </p>
                  </MDBFooter>
                </div>
              </Router>
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>

    );
  }
}

export default App;
